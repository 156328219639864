import clsx from "clsx"
import { customErr } from "../configs/errors"


//Throw an error and provide instructions on how to resolve it
const createError = (type,hostOrigin) => {
    throw new Error(clsx(
        customErr[type],
        "Or Follow the document link",
        hostOrigin + "/document.html"
    ))
}

export {
    createError
}