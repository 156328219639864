import { entityTypes, eventTypes } from "./configs/variables";
import { errorTypes } from "./configs/errors";
import { createError } from "./helpers/error";
import { clientTypes } from "./configs/variables";

if (typeof window != "undefined") {
  const selfURL = (() => {
    // Get the current script element
    const scriptElement = document.currentScript;

    // Get the URL of the current script
    const scriptUrl = scriptElement ? scriptElement.src : "";

    // Extract the host from the URL
    const url = new URL(scriptUrl);
    return url;
  })();

  //check integration container already exist 
  //if exist then remove that and create new one
  const alreadyInitSdk = document.getElementById("ruloans__sdk_integration");

  if (alreadyInitSdk) {
    alreadyInitSdk.remove();
  }

  // integration container start
  const integrationEl = document.createElement("div");
  integrationEl.className = "ruloans__integration";
  integrationEl.id = "ruloans__sdk_integration";
  document.body.appendChild(integrationEl);
  // integration container end

  // styles start
  const styles = document.createElement("style");
  styles.innerHTML = `
    .ruloans__integration{
        z-index: 2147483647 !important; 
        position: fixed !important;
        top: 0px !important;
        left: 0px !important; 
        height: 0 !important;
        width: 0 !important; 
        backface-visibility: hidden !important; 
        overflow: hidden !important;
        background:rgb(0, 0, 0,0) !important;
        transition:background 0.6s ease-in-out !important;
    }

    .ruloans__integration--active{
        height: 100% !important;
        width: 100% !important; 
        background:rgb(0, 0, 0,0.5) !important;
    }

    .ruloans__integration .ruloans__integration__iframe {
        min-height: 100% !important;
        opacity: 1 !important;
        height: 100% !important;
        position: relative !important;
        background: none !important;
        display: block !important;
        border: 0 none transparent;
        margin: 0px !important;
        padding: 0px !important;
        z-index: 2 !important;
        margin:auto !important;
    }
`;

  integrationEl.appendChild(styles);
  // styles end

  // iframe start
  const iframe = document.createElement("iframe");
  iframe.setAttribute("class", "ruloans__integration__iframe");
  iframe.setAttribute("src", selfURL.origin);
  iframe.setAttribute("allowtransparency", "true");
  iframe.setAttribute("frameBorder", "0");
  iframe.setAttribute(
    "allow",
    "otp-credentials; payment; clipboard-write; camera *"
  );
  iframe.setAttribute("width", "100%");
  iframe.setAttribute("height", "100%");
  integrationEl.appendChild(iframe);

  //make ruloans constructor globally accessible
  window.Ruloans = function ({
    entityType,
    clientType,
    authToken,
    pincode,
    loanVendor,
    applicationId,
    stageType,
    redirect_url,
    onError,
    onSuccess,
    onClose
  } = {}) {
    this.close = function () {
      integrationEl.classList.remove("ruloans__integration--active");
      onClose?.();
    };

    //Send message data to sdk when this funtion is invoked
    this.open = function () {
      if (clientTypes[clientType]) {
        if (entityTypes[entityType]) {
          const payload = {
            event: eventTypes.sdk_invoked,
            data: {
              entity: entityTypes[entityType],
              clientType: clientTypes[clientType],
              authToken,
              pincode,
              loanVendor,
              stageType,
              redirect_url,
              applicationId,
            },
          };
          iframe.contentWindow.postMessage(payload, "*");
          iframe.onload = () => {
            this.open();
          };
        } else {
          //create error when entity type is invalid
          return createError(errorTypes.invalid_entity, selfURL.origin);
        }
      } else {
        //create error when client type is invalid.
        return createError(errorTypes.invalid_client, selfURL.origin);
      }
    };

    //listen message of iframe
    window.addEventListener("message", (event) => {
      if (event.data.event == eventTypes.sdk_initiated) {
        integrationEl.classList.add("ruloans__integration--active"); //Show SDK Modal
      }
      if (event.data.event == eventTypes.sdk_closed) {
        this.close();
      }
    });


    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Modern browsers will display a generic message
    };

    //prevent to refresh the page
    window.addEventListener("beforeunload", handleBeforeUnload);
  };
}

// loanVendor:{
//     "id": 101,
//     "product_id": 1102,
//     "product_description": "Rupeek Gold Loan",
//     "joining_fee": null,
//     "product_vendor": "rupeek_gl",
//     "product_name": "Rupeek Gold Loan",
//     "annual_fee": null,
//     "product_image": null,
//     "journey_type": "integration",
//     "utm_journey_url": null,
//     "loan_type_name": "Gold Loan",
//     "contents": [],
//     "requirements": [],
//     "documents": []
// }
