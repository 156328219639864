import clsx from "clsx"
import { clientTypesArr, entityTypesArr } from "./variables"



const customErr = {
    invalid_entity: clsx("Invalid entityType. Please provide a valid entity type:", entityTypesArr,"."),
    invalid_client: clsx("Invalid clientType. Please provide a valid client type:", clientTypesArr,"."),
}


const errorTypes = {
    invalid_entity:"invalid_entity",
    invalid_client:"invalid_client"
}


export {
    customErr,
    errorTypes
}