const isProd = () => process.env.NODE_ENV == "production";
const WEBSITE_BASE = process.env.REACT_APP_WEBSITE_BASE_RUL;

const getS3ImgUrl = (imgPath) => process.env.REACT_APP_S3_IMAGE_BASE_URL + imgPath;


export {
    isProd,
    WEBSITE_BASE,
    getS3ImgUrl    
}